import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import ArticlePreview from "../components/ArticlePreview";
import PageInfo from "../components/PageInfo";
import slugify from "slugify";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const LayoutWrapper = styled.div`
  // background-color: white;
  position: relative;
  color: black;
`;

const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 130px 50px;
  margin-bottom: 100px;
  // padding: 80px 50px 0;
  // max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const pageData = {
  header: "Aktualności",
  mainTitle: "JB multimedia",
  subtitle: "W firmie, na świecie.",
};

const ArticlesPage = ({ data }) => {
  const {
    allDatoCmsArticle: { nodes },
  } = data;

  // Sort articles by date in descending order
  const sortedArticles = nodes.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <>
      <PageInfo
        header={pageData.header}
        mainTitle={pageData.mainTitle}
        subtitle={pageData.subtitle}
      />
      <Layout>
        <section className="news-page">
          <LayoutWrapper className="news-page-container">
            <ArticlesWrapper className="news-container-wrapper-elements">
              {sortedArticles.map(
                ({ title, featuredImage, date, articleContent }) => (
                  <ArticlePreview
                    key={title}
                    title={title}
                    date={date}
                    articleContent={[
                      articleContent.map((el) => {
                        return el.headingContent;
                      }),
                    ]}
                    image={featuredImage.fluid}
                    slug={slugify(title, { lower: true })}
                  />
                )
              )}
            </ArticlesWrapper>
          </LayoutWrapper>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>
          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    allDatoCmsArticle {
      nodes {
        title
        date
        articleContent {
          ... on DatoCmsHeading {
            id
            headingContent
          }
        }
        featuredImage {
          fluid(maxWidth: 500) {
            ...GatsbyDatoCmsFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Aktualności"
    description="Miejsce, gdzie dzielimy się informacjami, inspiracjami i nowościami związanymi z naszą firmą oraz branżą, w której działamy"
  />
);

export default ArticlesPage;
